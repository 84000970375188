import React from 'react';
import {Link} from 'gatsby';
import Head from '../components/head';
import thanksStyles from '../styles/thanks.module.scss';
import '../styles/index.scss';

const Thanks = () => {
    return (
        <div className={thanksStyles.messageContainer}>
            <Head />
            <div className={thanksStyles.message}>Thanks! Let's make this <span className={thanksStyles.highlight}>work!</span></div>
            <img className={thanksStyles.handshake} src="/assets/handshake.svg" alt="handshake" />
            <Link className={thanksStyles.back} to="/">back to home page</Link>
        </div>
    );
}

export default Thanks;
